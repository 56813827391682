import { useStore } from "@nanostores/preact";
import { useEffect } from "preact/hooks";
import {
  hasNoSearchResults,
  searchBarWidth,
  searchResults,
  searchText,
} from "stores/search";

let timeout: any = null;
const NavbarSearchInput = () => {
  const $searchText = useStore(searchText);

  useEffect(() => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(async () => {
      if (!$searchText) return searchResults.set([]);

      console.log($searchText);

      const r = await fetch("/api/search?q=" + encodeURIComponent($searchText));
      const j = await r.json();

      searchResults.set(
        j.products.map((it: any) => ({ label: it.name, href: it.path })),
      );
      hasNoSearchResults.set(!j.length);
    }, 200);
  }, [$searchText]);

  return (
    <input
      onInput={(e: any) => {
        searchText.set(e?.target?.value);
      }}
      value={$searchText}
      id="search"
      name="search"
      className={`block w-full rounded-md border-0 bg-neutral-800 py-1.5 pl-10 pr-3 text-gray-100 ring-1 ring-inset ring-neutral-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6`}
      placeholder="Search"
      type="search"
      ref={(cur) => {
        searchBarWidth.set(cur?.getBoundingClientRect().width || 320);
      }}
    />
  );
};

export default NavbarSearchInput;
